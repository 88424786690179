// In myApiHelpers.js

export const fetchItems = async (sessionToken, edgeId, days) => {
  const response = await fetch(
    `https://90xgounvad.execute-api.us-east-1.amazonaws.com/v2/timeseries/foo?edge_id=${edgeId}&days_ago=${days}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionToken,
      },
    }
  );
  return await response.json();
};
