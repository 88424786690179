import styles from "./sidenav.module.css";
import { NavLink } from "react-router-dom";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { navData } from "../lib/navData";
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

export default function Sidenav() {
  const { logout } = useAuth0();
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <div className={open ? styles.sidenav : styles.sidenavClosed}>
      
      {navData.map((item) => {
        return (
          <NavLink key={item.id} className={styles.sideitem} to={item.link}>
            {item.icon}
            {open && <span className={styles.linkText}>{item.text}</span>}
          </NavLink>
        );
      })}
      <button className={styles.menuBtn} onClick={toggleOpen}>
        {open ? (
          <KeyboardDoubleArrowLeftIcon />
        ) : (
          <KeyboardDoubleArrowRightIcon />
        )}
      </button>

      {/* Set to toggleopen temp */}
      <button className={styles.logoutBtn} onClick={logout}>
        {open ? <PowerSettingsNewIcon /> : <PowerSettingsNewIcon />}
      </button>
    </div>
  );
}
