import React, { useEffect, useState } from "react";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { useAuth0 } from "@auth0/auth0-react";
import { fetchItems } from "./myApiHelpers";
import { calculateSeaLevelPressure } from "./pressureCalc";
import { Slider } from "@material-ui/core";

const Assets = () => {
  const [edgeId, setEdgeId] = useState("108");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { user, getAccessTokenSilently } = useAuth0();
  const [selectedDay, setSelectedDay] = useState(1);

  useEffect(() => {
    const getUserData = async () => {
      try {
        setLoading(true);
        setError(null);
        const accessToken = await getAccessTokenSilently({
          audience: `https://kiowa.us.auth0.com/api/v2/`,
          scope: "read:current_user",
        });
        const response = await fetchItems(accessToken, edgeId, selectedDay);
        setData(response);
      } catch (e) {
        setError("An error occurred while fetching the data.");
        console.log(e.message);
      } finally {
        setLoading(false);
      }
    };

    getUserData();
  }, [getAccessTokenSilently, user?.sub, edgeId, selectedDay]);

  return (
    <div className="App">
      <body>
        <h1> My Metrics</h1>
        <div>
          <label htmlFor="edgeId">Select Edge ID: </label>
          <select
            name="edgeId"
            value={edgeId}
            onChange={(e) => setEdgeId(e.target.value)}
          >
            <option value="108">Edge ID 108</option>
            <option value="109">Edge ID 109</option>
            <option value="111">Edge ID 111</option>
            <option value="121">Edge ID 121</option>
          </select>
        </div>
        <div>
          <label htmlFor="daySlider">Select Day: </label>
          <Slider
            id="daySlider"
            value={selectedDay}
            onChange={(e, value) => setSelectedDay(value)}
            min={1}
            max={90}
            step={1}
          />
        </div>
        <h1>Temperature / Humidity</h1>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart
            data={data.map((item, index) => ({
              ...item,
              seaLevelPressure: calculateSeaLevelPressure(
                item.elevation,
                item.pressure
              ),
            }))}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="edgeTimestamp" />
            <YAxis domain={["dataMin - 30", "dataMax"]} />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="temperature" stroke="#8884d8" />
            <Line type="monotone" dataKey="humidity" stroke="#82ca9d" />
          </LineChart>
        </ResponsiveContainer>

        <h2>Recorded Pressure and Calculated SeaLevel Pressure</h2>

        <LineChart
          data={data.map((item, index) => item)}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: -10,
          }}
        >
          <CartesianGrid strokeDasharray="10 10" />
          <XAxis
            dataKey={(d) => new Date(d.edgeTimestamp).toLocaleDateString()}
          />
          <YAxis type="number" domain={["dataMin - 20", "dataMax + 20"]} />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="temperature" stroke="#8884d8" />
          <Line type="monotone" dataKey="humidity" stroke="#82ca9d" />
        </LineChart>

        <ResponsiveContainer width="100%" height={400}>
          <LineChart
            width={1000}
            height={300}
            data={data.map((item, index) => ({
              ...item,
              seaLevelPressure: calculateSeaLevelPressure(
                item.elevation,
                item.pressure
              ),
            }))}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="edgeTimestamp" />
            <YAxis domain={["dataMin - 30", "dataMax + 30"]} />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="pressure" stroke="#8884d8" />
            <Line type="monotone" dataKey="seaLevelPressure" stroke="#82ca9d" />
          </LineChart>
        </ResponsiveContainer>
        <table style={{ width: "100%" }}>
          <tr>
            <th>Edge ID</th>
            <th>Processed Timestamp</th>
            <th>Edge Timestamp</th>
            <th>Temperature (C)</th>
            <th>Pressure (hPa)</th>
            <th>Elevation (m)</th>
            <th>Sea Level Pressure (hPa)</th>
          </tr>

          {data
            .slice()
            .reverse()
            .map((item, index) => {
              const seaLevelPressure = calculateSeaLevelPressure(
                item.elevation,
                item.pressure
              );
              return (
                <tr key={index}>
                  <td>{item.GSI1PK}</td>
                  <td>{new Date(item.GSI1SK).toString()}</td>
                  <td>{new Date(item.edgeTimestamp).toString()}</td>
                  <td>{item.temperature}</td>
                  <td>{item.pressure}</td>
                  <td>{item.elevation}</td>
                  <td>{seaLevelPressure.toFixed(2)}</td>
                </tr>
              );
            })}
        </table>
      </body>
    </div>
  );
};

export default Assets;
