import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const Home = () => {
  const [setData] = useState([]);
  const { user, getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    const getUserMetadata = async () => {
      const domain = "auredia-dev.us.auth0.com";

      try {
        const accessToken = await getAccessTokenSilently({
          audience: `https://${domain}/api/v2/`,
          scope: "read:current_user",
        });

        const userDetailsByIdUrl = `https://90xgounvad.execute-api.us-east-1.amazonaws.com/v2/edge`;

        await fetch(userDetailsByIdUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((response) => response.json())
          .then((actualData) => {
            setData(actualData);
          })
          .catch((err) => {
            console.log(err.message);
          });
      } catch (e) {
        console.log(e.message);
      }
    };

    getUserMetadata();
  }, [getAccessTokenSilently, user?.sub, setData]);

  return (
    <div class="App">
      <body>
        <h1>Platform Management</h1>
        
      </body>
    </div>
  );
};

export default Home;
