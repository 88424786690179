export const deleteEdgeDevice = async (sessionToken, thingName) => {
  const response = await fetch(
    `https://5lpvkiuz0f.execute-api.us-east-1.amazonaws.com/v1/edge/deleteEdgeDevice?thingName=${thingName}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionToken,
      },
    }
  );
  return await response.json();
};