import HomeIcon from "@mui/icons-material/Home";
import BarChartIcon from "@mui/icons-material/BarChart";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SensorsIcon from "@mui/icons-material/Sensors";

export const navData = [
  {
    id: 0,
    icon: <HomeIcon />,
    text: "Home",
    link: "/",
  },
  {
    id: 1,
    icon: <SensorsIcon />,
    text: "Edge",
    link: "edge",
  },
  {
    id: 2,
    icon: <BarChartIcon />,
    text: "Metrics",
    link: "metrics",
  },
  {
    id: 3,
    icon: <SettingsIcon />,
    text: "Settings",
    link: "settings",
  },
  {
    id: 4,
    icon: <AccountCircleIcon />,
    text: "Personal Info",
    link: "userinfo",
  },
];
