import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { createEdgeDevice } from "./createEdgeDevice";
import { listEdgeDevices } from "./listEdgeDevices";
import { deleteEdgeDevice } from "./deleteEdgeDevice";
import "./styles.css";

const Assets = () => {
  const [edgeId, setEdgeId] = useState("");
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false); // Add state for modal visibility
  const { user, getAccessTokenSilently } = useAuth0();

  const getListEdgeDevices = async () => {
    try {
      setLoading(true);
      setError(null);
      const accessToken = await getAccessTokenSilently({});
      const response = await listEdgeDevices(accessToken);
      setData(response);
    } catch (e) {
      setError("An error occurred while fetching the data.");
      console.log(e.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateEdgeDevice = async () => {
    const confirmed = window.confirm(`Are you sure you want to create edge device "${edgeId}"?`);
    if (!confirmed) {
      return;
    }
  
    try {
      const accessToken = await getAccessTokenSilently({});
      const response = await createEdgeDevice(accessToken, edgeId);
      console.log(response);
      setShowModal(true); // Show modal if response status is 200
      setResponse(response);
      getListEdgeDevices();
    } catch (e) {
      setError("An error occurred while creating the edge device.");
      console.log(e.message);
    }
  };
  

  const handleDeleteEdgeDevice = async (edgeId) => {
    const confirmed = window.confirm(`Are you sure you want to delete edge device "${edgeId}"?`);
    if (!confirmed) {
      return;
    }
  
    try {
      const accessToken = await getAccessTokenSilently({});
      const response = await deleteEdgeDevice(accessToken, edgeId);
      console.log(response);
      // Remove the deleted item from the data array
      setData((prevData) => prevData.filter((item) => item.edgeDevice !== edgeId));
      getListEdgeDevices();
      window.alert(`${JSON.stringify(response['message'])}`);
    } catch (e) {
      setError("An error occurred while deleting the edge device.");
      console.log(e.message);
    }
  };
  
  useEffect(() => {
    const getEdgeDevices = async () => {
      try {
        setLoading(true);
        setError(null);
        const accessToken = await getAccessTokenSilently({});
        const response = await listEdgeDevices(accessToken, edgeId);
        console.log(response)
        setData(response);
      } catch (e) {
        setError("An error occurred while fetching the data.");
        console.log(e.message);
      } finally {
        setLoading(false);
      }
    };

    getEdgeDevices();
  }, [getAccessTokenSilently, user?.sub, edgeId]);
  return (
    <div class="App">
      <body>
        <h1>Platform Edge Devices</h1>
        {isLoading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>Error: {error}</div>
        ) : (
          <table>
            <thead>
              <tr>
                <th>Edge Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>{item.edgeDevice}</td>
                  <td>
                    <button onClick={() => handleDeleteEdgeDevice(item.edgeDevice)}>
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <div>
          <h3> Create Device</h3>
          <label htmlFor="edgeId">Edge ID:</label>
          <input
            type="text"
            id="edgeId"
            value={edgeId}
            onChange={(e) => setEdgeId(e.target.value)}
          />
          <button onClick={handleCreateEdgeDevice}>Create Edge Device</button>
        </div>
        {showModal && (
          <div className="modal">
            <h2>${JSON.stringify(response['message'])}</h2>
            <p>${JSON.stringify(response)}</p>
            <button onClick={() => setShowModal(false)}>OK</button>
          </div>
        )}
      </body>
    </div>
  );
  };
  
  export default Assets;
  