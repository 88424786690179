import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

import { Auth0Provider } from "@auth0/auth0-react";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Auth0Provider
  domain="auredia-dev.us.auth0.com"
  clientId="aW4yvGJGYBbs16wOkit1bd6hjYJYP4gL"
  redirectUri={window.location.origin}
  audience="https://5lpvkiuz0f.execute-api.us-east-1.amazonaws.com"
  scope="read:management write:management"
  
  >
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </Auth0Provider>
);
